import React from "react";
// import FormfacadeEmbed from "@formfacade/embed-react";
const VolunteerForm = () => {
  return (
    <div>
      {/* FORM FOR CHILDREN */}
      {/* <FormfacadeEmbed

formFacadeURL="https://formfacade.com/include/102696232577715632565/form/1FAIpQLScZqMftz4ZCGGhpjfYAVoamRLcbtiPp0fAbxSnGZutrWY5AAA/classic.js/?div=ff-compose"

onSubmitForm={() => alert("Form submitted Succesfully")}

/>  */}

      {/* fORM FOR ELDER PEOPLE */}
      {/* <FormfacadeEmbed

formFacadeURL="https://formfacade.com/include/102696232577715632565/form/1FAIpQLSeA2KG0Tlhxmo83xEOYk2IJp5x6BPbqcS9vj4g7vGkvX2N76w/classic.js/?div=ff-compose"

onSubmitForm={() => console.log('Form submitted')}

/> */}

      {/* FORM FOR VOLUNTEER */}
      {/* <FormfacadeEmbed

formFacadeURL="https://formfacade.com/include/102696232577715632565/form/1FAIpQLSf-Xfmsyr1PRcnUab7ud0DCUGU2Rx7pD6QcaauFrFT1HCmWWA/classic.js/?div=ff-compose"

onSubmitForm={() => console.log('Form submitted')}

/> */}

      {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf8OmJ7qfCIQmTS2FmQl3peesYRA5Ne5fevXdoxO4Oxn3ruFQ/viewform?embedded=true" width="640" height="9429" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
    </div>
  );
};

export default VolunteerForm;
